@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&family=Fredericka+the+Great&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    background-color: rgb(197, 196, 196);
    height: 98vh;
}

.background{
    height: 60vh;
    min-width: 98vw;
}

.flexy{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-brand{
    font-family: 'Fredericka the Great', cursive !important;
    font-size: 2.2rem;
}

.banner{
    font-family: 'Bree Serif', serif;
    font-size: 3rem;
    height: 3rem;
}

.card{
    max-width: 30rem;
    width: 95%;
    border-radius: 1rem;
    background-image: linear-gradient(135deg, rgb(185, 180, 180) 10%, #ffffff 100%);  
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.card:hover{
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.card-header{
    text-transform: capitalize;
    font-family: 'Fredericka the Great', cursive !important;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
}

img{
    height: 6rem !important;
    width: 6rem !important;
    border-radius: 3rem;
}

.card-body{
    font-family: 'Montserrat', sans-serif;
    min-height: 25rem;
    font-weight: 500;
}

.card-footer{
    font-family: 'Bree Serif', serif;
    text-transform: capitalize;
    color: #777777;
}

.bold{
    font-weight: bold;
}

input{
    max-width: 50rem;
    width: 95%;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;  
    border-radius: 5rem !important;
}

input::-webkit-input-placeholder{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;   
}

input:-ms-input-placeholder{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;   
}

input::placeholder{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;   
}

.btn{
    background-color: rgb(185, 180, 180);
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important; 
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

